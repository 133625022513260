<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      head-title="人员出入境"
      :head-btn-options="headBtnOptions"
      @head-saveBack="headSave"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain" style="padding: 0 12px">
        <el-form
          ref="dataForm"
          :model="dataForm"
          label-width="150px"
          :disabled="formType == 'view'"
          :rules="rules"
        >
          <div class="formTopic" style="margin-bottom: 12px">基础信息</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="姓名" prop="userName">
                <el-input
                  v-model="dataForm.userName"
                  @focus="selectPeople"
                  clearable
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="隶属组织" prop="orgName">
                <el-input
                  v-model="dataForm.orgName"
                  disabled
                  placeholder="请选择隶属组织"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
          <el-row> -->
            <el-col :span="8">
              <el-form-item label="行程状态" prop="tripState">
                <el-select
                  v-model="dataForm.tripState"
                  placeholder="请选择行程状态"
                >
                  <el-option
                    v-for="item in trip_state"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="出发地" prop="startPlace">
                <el-input
                  v-model="dataForm.startPlace"
                  @focus="handleRegionOPen('startPlace')"
                  placeholder="请选择出发地"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="目的地" prop="endPlace">
                <el-input
                  v-model="dataForm.endPlace"
                  @focus="handleRegionOPen('endPlace')"
                  placeholder="请选择目的地"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
          <el-row> -->
            <el-col :span="8">
              <el-form-item label="出发日期" prop="startDate">
                <el-date-picker
                  v-model="dataForm.startDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务项目" prop="prjName">
                <el-input
                  v-model="dataForm.prjName"
                  @focus="handleSelectProject"
                  clearable
                  placeholder="请选择服务项目"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="dataForm.remark"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  show-word-limit
                  maxlength="200"
                  placeholder="请填写备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          style="
            border-bottom: 1px solid rgb(187, 187, 187);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            padding-bottom: 8px;
          "
        >
          <div>附件</div>
          <el-upload
            v-show="hideUpload"
            class="upload-demo"
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="handleBeforeUpload"
            ref="uploadRef"
            :headers="headers"
          >
            <el-button size="small" ref="upload">上传</el-button>
          </el-upload>
        </div>

        <div class="shuttleBackBox">
          <!-- <head-layout
                    head-title="附件"
                    :head-btn-options="uploadBtnOptions"
                    @head-upload="handleUpload"
                /> -->
          <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOption"
            :table-data="fileList"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @grid-row-detail-click="rowView"
          >
            <template #customBtn="{ row }" v-if="hideUpload">
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                @click="rowDonlowd(row)"
                >下载</el-button
              >
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                @click="rowRemove(row)"
                >删除</el-button
              >
            </template>
          </grid-layout>
        </div>
      </div>
    </div>

    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectPeopleData"
      ></UserDetpDialog>
    </el-dialog>
    <selectProjectByOrg
      ref="selectProjectByOrg"
      @callback="projectByOrgCallback"
    ></selectProjectByOrg>
    <selectProjectDialogVue ref="selectProjectDialog" @changeProject="changeProject"></selectProjectDialogVue>
    <el-dialog
      :title="`请选择${regionTitle}`"
      v-dialogDrag
      :visible.sync="regionModel"
      width="40%"
      class="region-dialog"
    >
      <avue-tree
        :option="treeOption"
        ref="avueTree"
        v-loading="treeLoading"
        :data="treeData"
        @node-click="nodeClick"
      />
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="prviewDialogVisible"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="prviewDialogImageUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="prviewDialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import mixins from "./mixins";

import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import selectProjectByOrg from "./components/selectProjectByOrg.vue";
import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";
import { downloadFileBlob } from "@/util/util";
import { dictionaryBiz } from "@/api/reportTasks";
import { mapGetters } from "vuex";

import * as API from "@/api/siteManagement/personEAExit";
import { getLazyTree, getAllTree } from "@/api/base/region";
import website from "@/config/website";
import dataSubjectAddVue from "../../business/datacenter/DataAssets/dataSubjectAdd.vue";
import selectProjectDialogVue from '@/views/business/safe/blacklistOrganization/components/selectProjectDialog.vue';
export default {
  mixins: [mixins],
  components: {
    UserDetpDialog,
    selectProjectByOrg,
    GridLayout,
    HeadLayout,
    selectProjectDialogVue
  },
  data() {
    return {
      trip_state: [],
      tableLoading: false,
      treeLoading: false,
      treeData: [],
      treeOption: {
        nodeKey: "id",
        lazy: false,
        multiple: false,
        addBtn: false,
        menu: false,
        size: "small",
        defaultExpandAll: true,
        props: {
          // labelText: "标题",
          // label: "title",
          // value: "value",
          // children: "children",
          children: "childList",
          label: "name",
          value: "code",
        },
      },

      fileList: [],
      deptShow: false,

      type: "",
      hideUpload: true,
      topCode: "10",
      regionModel: false,
      regionTitle: "",
      regoonType: "",
      selecRegionData: {},
      dialogLoading: false,
      tableOption: {
        linklabel: "name",
        selection: false,
        menuFixed: "right",
        menuWidth: 100,
        column: [
          {
            label: "名称",
            prop: "name",
            align: "center",
            overHidden: true,
          },
          {
            label: "类型",
            prop: "type",
            align: "center",
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "date",
            align: "center",
            overHidden: true,
          },
        ],
      },
      prviewDialogVisible: false,
      prviewDialogImageUrl: "",
    };
  },
  computed: {
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    ...mapGetters(["userInfo"]),
    uploadBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "上传",
        emit: "head-upload",
        type: "button",
        icon: "",
        btnOptType: "upload",
      });
      return buttonBtn;
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      // this.headTitle = "项目配置";
      this.dataForm.id = this.$route.query.id;
      this.getDetail();
    } else if (type == "view") {
      // this.headTitle = "查看项目配置";
      this.hideUpload = false;
      this.dataForm.id = this.$route.query.id;
      this.getDetail();
    } else {
      this.$set(this.dataForm, "orgId", this.userInfo.dept_id);
      this.$set(this.dataForm, "orgName", this.userInfo.deptName);
      this.$set(this.dataForm, "userId", this.userInfo.user_id);
      this.$set(this.dataForm, "userName", this.userInfo.real_name);
      this.$set(this.dataForm, "sex", this.userInfo.sex);
    }
    dictionaryBiz("trip_state").then((res) => {
      this.trip_state = res.data.data;
    });
    // this.initTree();
  },
  methods: {
    initTree() {
      this.treeData = [];
      // getLazyTree(this.topCode).then((res) => {
      //   this.treeData = res.data.data.map((item) => {
      //     return {
      //       ...item,
      //       leaf: !item.hasChildren,
      //     };
      //   });
      // });
      this.treeLoading = true
      getAllTree().then((res) => {
        this.treeLoading = false
        this.treeData = this.filterTreeDataByAttr(res.data.data).map((item) => {
          return {
            ...item,
            leaf: !item.hasChild,
          };
        });
      });
    },

    handleRegionOPen(type) {
      this.initTree();
      this.regionTitle = type === "startPlace" ? "出发地" : "目的地";
      this.regoonType = type;
      this.regionModel = true;
    },
    nodeClick(data) {
      if (data.regionLevel) {
        this.selecRegionData = data;
      } else {
        this.$message.warning("只能选择国家");
      }
    },
    handleCancel() {
      this.$refs.avueTree.setCurrentKey();
      this.$refs.avueTree.filterValue = "";
      this.dialogLoading = false;
      this.regionModel = false;
    },
    handleSave() {
      if (!this.selecRegionData.regionLevel) {
        this.$message.warning("只能选择国家");
        return false;
      }
      this.regionModel = false;
      if (this.regoonType === "startPlace") {
        this.$set(this.dataForm, "startPlace", this.selecRegionData.name);
      }
      if (this.regoonType === "endPlace") {
        this.$set(this.dataForm, "endPlace", this.selecRegionData.name);
      }
      this.$refs.avueTree.setCurrentKey();
      this.$refs.avueTree.filterValue = "";
      this.selecRegionData = {};
    },

    selectPeopleData(row, deptId, treeDeptName) {
      this.$set(this.dataForm, "userId", row.id);
      this.$set(this.dataForm, "userName", row.realName);
      this.$set(this.dataForm, "orgId", deptId);
      this.$set(this.dataForm, "orgName", treeDeptName);
      this.$set(this.dataForm, "sex", row.sex);
      this.deptShow = false;
    },

    selectPeople() {
      this.deptShow = true;
    },
    handleSelectProject() {
     // this.$refs.selectProjectByOrg.init();
      this.$refs.selectProjectDialog.init()
    },
    projectByOrgCallback(row) {
      this.$set(this.dataForm, "prjId", row.id);
      this.$set(this.dataForm, "prjName", row.prjName);
    },
    changeProject(row){
      this.$set(this.dataForm, "prjId", row.id);
      this.$set(this.dataForm, "prjName", row.prjName);
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.dataForm.startPlace === this.dataForm.endPlace) {
            this.$message.warning("出发地和目的地不能为一个地方,请重新选择");
            return false;
          }
          this.dataForm.files = JSON.stringify(this.fileList);
          this.$loading();

          API.submit(this.dataForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.$loading().close();
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
            })
            .catch((err) => {
              this.$loading().close();
            });
        }
      });
    },
    // 根据ID查表单详情
    async getDetail() {
      this.tableLoading = true;
      await API.detail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          this.dataForm.tripState = String(this.dataForm.tripState);
          this.fileList = this.dataForm.files
            ? JSON.parse(this.dataForm.files)
            : [];
        }
        this.tableLoading = false;
      });
    },
    handleUpload() {
      this.$refs.upload.$el.click();
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      let arr = [".avi", ".mpg", ".mpeg", ".mp4", ".mp3", ".wav", ".etc"];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
        return false;
      }
      if (arr.includes(lastName)) {
        this.$message.warning("不支持上传视频、音频类文件");
        return false;
      }
      if (this.fileList.length >= 50) {
        this.$message.warning("上传文件数量不能超过50个");
        return false;
      }
    },
    // 新增上传文件
    handleSuccess(res, file, fileList) {
      // console.log("file-list", fileList);
      // console.log("file", file);
      // let list = fileList.map((i) => {
      //   if (i.response) {
      //     return {
      //       name: i.name,
      //       id: i.response.data.attachId,
      //       type: i.raw.type,
      //       date: dateFormat(new Date(), "yyyy-MM-dd"),
      //       link: i.response.data.link,
      //     };
      //   } else {
      //     return {
      //       name: i.name,
      //       id: i.attachId,
      //       link: i.link,
      //     };
      //   }

      // });
      if (res && res.code == 200) {
        const fileName = file.name;
        const fileData = file.response.data;
        const data = {
          name: fileName,
          id: fileData.attachId,
          type: fileName.split(".")[1],
          date: dateFormat(new Date(), "yyyy-MM-dd"),
          link: fileData.link,
        };
        this.fileList.push(data);
      }
      // list.forEach((item) => {
      //   this.fileList.push(item);
      // });
      this.$refs.uploadRef.clearFiles();
    },
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.fileList.splice(
          this.fileList.findIndex((v) => v.id === row.id),
          1
        );
      });
    },
    rowDonlowd(row) {
      downloadFileBlob(row.link, row.name);
    },
    rowView(row) {
      this.prviewDialogImageUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(row.link));
      this.prviewDialogVisible = true;
      //excels表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.region-dialog {
  ::v-deep .el-dialog {
    position: relative;
  }
  ::v-deep .el-dialog__body {
    overflow-y: auto;
    height: 400px;
  }
  ::v-deep .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20001;
  }
}
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
</style>
