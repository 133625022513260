<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="70%"
    class="qmDialog"
    @close="closeDialog"
  >
    <el-container>
      <CommonTree
        ref="commonTreeCore"
        treeTitle="组织列表"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="treeNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
      />
      <el-main>
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>

        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowSelect(row)"
              >选择
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </el-dialog>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import { prjbasicinfoPage } from "@/api/safe/itemList";
import { getLazyDeptTree, selectChildren } from "@/api/system/dept";
import { mapGetters } from "vuex";
export default {
  components: {
    CommonTree,
    GridLayout
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      searchTitle: "title",
      contractId: "",
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "项目名称",
          prop: "prjCode",
          span: 5,
          placeholder: "项目名称或编号",
        },
        {
          label: "立项日期",
          prop: "prjDate",
          span: 5,
          type: "date-format",
          placeholder: "请选择立项日期",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "项目名称",
            prop: "prjName",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "项目编码",
            prop: "prjCode",
            align: "center",
            overHidden: true,
            width: 200,
          },

          {
            label: "区域",
            prop: "areName",
            align: "center",
            overHidden: true,
          },
          {
            label: "立项日期",
            prop: "prjDate",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    //获取树数据
    lazyDeptTree() {
      selectChildren('1,2,4').then((res) => {
          this.treeData = res.data.data;

        });
      // getLazyDeptTree({
      //   categorys: "1,2,4",
      //   parentId: this.userInfo.dept_id,
      // }).then((res) => {
      //   this.treeData = res.data.data;
      // });
    },
    treeNodeClick(node) {
      this.contractId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    init(query) {
      this.showDialog = true;
      this.title = "选择项目";

      this.onLoad(this.page, query);
      this.lazyDeptTree();

      this.$nextTick(()=>{
        // console.log('=>',this.$refs.commonTreeCore.filterText)
        this.$refs.commonTreeCore.filterText=''
      })
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.userId) params.userId = this.userId;
      if (this.companyCode) params.companyCode = this.companyCode;
      if (this.contractId) params.contractId = this.contractId;
      prjbasicinfoPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择项目
    rowSelect(row) {
      this.showDialog = false;
      this.$emit("callback", row);
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
